import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import Layout from '@/layouts/Default'
import Hero from '@/components/Hero'
import HeroCard from '@/components/Hero/HeroCard'
import BlockExperiences from '@/components/Experiences'
import { BOOK_NOW } from '@/utils/gtmEvents'
import BlockSlider from '@/components/Block/TextSlider'
import CircularLabelButton from '@/components/CircularLabelButton'
import Button from '@/components/Button'
import Link from '@/components/Link'
import { initTock, resetTock } from '@/utils/tock'
import { isMobileState } from '@/recoil/layout'
import { useRecoilValue } from 'recoil'

const Attendees = styled.div`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 1.4rem;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`

const Experience = ({ data, pageContext: { requireAgeGate, pagePath, marketCode: pageMarketCode } }) => {
  const isMobile = useRecoilValue(isMobileState)
  const doc = data.drupal.nodeById

  const heroItems = [
    {
      icon: 'horloge',
      label: 'Duration',
      content: doc.fieldExperienceBookingDetails.entity?.fieldBookingDetailsDuration
    },
    {
      icon: 'credit-card',
      label: 'Price',
      content: doc.fieldExperienceBookingDetails.entity?.fieldBookingDetailsPrice
    },
    {
      icon: 'capacity-icon',
      label: 'Capacity',
      content: doc.fieldExperienceBookingDetails.entity?.fieldBookingDetailsProdCode
    }
  ]

  const otherExperiences = doc.fieldXpOtherExperiences.filter(e => e.entity?.fieldExperienceItem).map((experience) => (
    {
      status: experience.entity?.fieldExperienceItem.entity?.status,
      name: experience.entity?.fieldExperienceItem.entity?.fieldExperienceName,
      tags: experience.entity?.fieldExperienceItem.entity?.fieldExperienceCategory.map((tag) => tag.entity?.name),
      image: experience.entity?.fieldExperienceItem.entity?.fieldExperienceImageDesktop.url,
      price: experience.entity?.fieldExperienceItem.entity?.fieldExperienceBookingDetails.entity?.fieldBookingDetailsPrice,
      link: experience.entity?.fieldExperienceItem.entity?.path.alias
    }
  )).filter(xp => !!xp.status)

  const gtm = () => {
    window.dataLayer && window.dataLayer.push({
      event: BOOK_NOW
    })
  }

  const actions = doc.fieldExperienceDetails.entity?.fieldStandardContentBlock[0]?.entity?.fieldStandardContentCta2Link
    ? [
      <Button
        onClick={ () => {
          triggerTockPlugin()
          gtm()
        } }
        key={ 1 }
      >
        {doc.fieldExperienceDetails.entity?.fieldStandardContentBlock[0]?.entity?.fieldStandardCta?.title}
      </Button>,,
      <Link to={ doc.fieldExperienceDetails.entity?.fieldStandardContentBlock[0]?.entity?.fieldStandardContentCta2Link?.url.path } key={ 2 }>{doc.fieldExperienceDetails.entity?.fieldStandardContentBlock[0]?.entity?.fieldStandardContentCta2Link?.title}</Link>
      ]
    : [
      <Button
        onClick={ () => {
          triggerTockPlugin()
          gtm()
        } }
        key={ 1 }
      >
        {doc.fieldExperienceDetails.entity?.fieldStandardContentBlock[0]?.entity?.fieldStandardCta?.title}
      </Button>
      ]

  const triggerTockPlugin = _ => {
    document.getElementById('Tock_widget_container').querySelector('.TockButton-link').click()
  }

  useEffect(() => {
    return () => {
      resetTock()
    }
  }, [])

  useEffect(() => {
    if (!isMobile) {
      resetTock()
    }
    initTock(doc.fieldExperienceCellarDoor[0].entity?.fieldRelatedContentType?.entity?.fieldTockBusinessName)
  }, [])

  const contactFallBackButton = [
    <Button
      onClick={ () => gtm() }
      key={ 1 } target='_blank' to={ doc.fieldExperienceBookingDetails.entity?.fieldBookingDetailsBookCta.url.path }
    >{ doc.fieldExperienceBookingDetails.entity?.fieldBookingDetailsBookCta.title }
    </Button>
  ]

  return (
    <Layout entityBundle='experience' path={ pagePath } requireAgeGate={ requireAgeGate } pageMarketCode={ pageMarketCode }>
      <Helmet>
        <title>{doc.fieldExperienceMetaTags.entity?.fieldMetaTitle}</title>
        <meta name='description' content={ doc.fieldExperienceMetaTags.entity?.fieldMetaDescription } />
        <meta property='og:title' content={ doc.fieldExperienceMetaTags.entity?.fieldMetaTitle } />
        <meta property='og:description' content={ doc.fieldExperienceMetaTags.entity?.fieldMetaDescription } />
        <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}` } />
        <meta property='og:site_name' content='Cloudy Bay' />
        <meta property='og:type' content='website' />
        {doc.fieldExperienceMetaTags.entity?.fieldMetaOpenGraphImage && <meta propery='og:image' content={ doc.fieldExperienceMetaTags.entity?.fieldMetaOpenGraphImage?.url } /> }
      </Helmet>
      <Hero
        title={ doc.fieldExperienceName }
        text={ doc.fieldExperienceDescription }
        image={ doc.fieldExperienceImageDesktop.url }
        tags={ doc.fieldExperienceCategory.map((tag) => tag.entity?.name) }
        circularButton={ [
          <CircularLabelButton
            id='textcercle1'
            key={ 1 }
            label='Back'
            nbLabels={ 9 }
            color='white'
            icon='arrow-left'
            to={ doc.fieldExperienceCellarDoor[0].entity?.fieldRelatedContentType?.entity?.path?.alias }
          />
        ] }
      >
        <HeroCard
          title={ doc.fieldExperienceBookingDetails.entity.fieldBookingDetailsTitle }
          items={ heroItems }
          display='column'
          actions={ doc.fieldExperienceTockId && doc.fieldExperienceTockId !== ''
            ? [
              <div
                key={ 1 }
                onClick={ () => {
                  gtm()
                } }
                id='Tock_widget_container'
                data-tock-display-mode='Button'
                data-tock-color-mode='Blue'
                data-tock-locale='en-nz'
                data-tock-timezone='Pacific/Auckland'
                data-tock-offering={ doc.fieldExperienceTockId }
              />
              ]
            : contactFallBackButton }
          block='private-experience'
        />
      </Hero>

      <BlockSlider
        id='textcercle2'
        subtitle={ doc.fieldExperienceDetails.entity?.fieldStandardContentBlock[0]?.entity?.fieldStandardSubtitle }
        title={ doc.fieldExperienceDetails.entity?.fieldStandardContentBlock[0]?.entity?.fieldStandardTitle }
        text={ doc.fieldExperienceDetails.entity?.fieldStandardContentBlock[0]?.entity?.fieldStandardDescription }
        actions={ doc.fieldExperienceTockId && doc.fieldExperienceTockId !== '' ? actions : contactFallBackButton }
        images={ doc.fieldExperienceDetails.entity?.fieldStandardImageCarrousel.entity?.fieldCarrouselImageItem.map((image) => (
          {
            src: image.entity?.fieldImageItem.url,
            alt: image.entity?.fieldImageItem.alt
          }
        )) }
        insertAfterText={
          <Attendees>{ doc.fieldExpNumberOfAttendees }</Attendees>
        }
      />
      <BlockExperiences
        title='Discover our other experiences'
        items={ otherExperiences }
      />
    </Layout>
  )
}

export const query = graphql`
  query ExperienceQuery($nid: String!) {
    drupal {
      nodeById(id: $nid) {
        ... on Drupal_NodeExperience {
          nid
          fieldExperienceMetaTags {
            entity {
              ... on Drupal_ParagraphMetaTags {
                fieldMetaTitle
                fieldMetaDescription
                fieldMetaOpenGraphImage {
                  url
                }
              }
            }
          }
          fieldExperienceCountry {
            entity {
              ... on Drupal_TaxonomyTermCountry {
                fieldCountryCode
              }
            }
          }
          fieldExperienceName
          fieldExperienceDescription
          fieldExpNumberOfAttendees
          fieldExperienceTockId
          fieldExpDiscoverCtaLabel
          fieldExperienceImageDesktop {
            url
            alt
          }
          fieldExperienceImageMobile {
            url
            alt
          }
          fieldExperienceCellarDoor {
            entity {
              ... on Drupal_TaxonomyTermXpDomains {
                name
                fieldRelatedContentType {
                  entity {
                    path {
                      alias
                    }
                    ... on Drupal_NodeCellarDoor {
                      fieldTockBusinessName
                    }
                  }
                }
              }
            }
          }
          fieldExperienceCategory {
            entity {
              ... on Drupal_TaxonomyTermXpCategories {
                name
              }
            }
          }
          fieldExperienceBookingDetails {
            entity {
              ... on Drupal_ParagraphExperienceBookingDetails {
                fieldBookingDetailsPrice
                fieldBookingDetailsTitle
                fieldBookingDetailsDuration
                fieldBookingDetailsProdCode
                fieldBookingDetailsBookCta {
                  uri
                  url{
                    path
                  }
                  title
                  url {
                    path
                  }
                }
              }
            }
          }
          fieldExperienceDetails {
            entity {
              ... on Drupal_ParagraphStandardContentAndImageCarro {
                fieldCarrouselPosition
                fieldStandardContentBlock {
                  entity {
                    ... on Drupal_ParagraphStandardContentBlock {
                      fieldStandardTitle
                      fieldStandardSubtitle
                      fieldStandardDescription
                      fieldStandardCta {
                        uri
                        url{
                          path
                        }
                        title
                        url{
                          path
                        }
                      }
                      fieldStandardContentCta2Link {
                        uri
                        url{
                          path
                        }
                        title
                        url {
                          path
                        }
                      }
                    }
                  }
                }
                fieldStandardImageCarrousel {
                  entity {
                    ... on Drupal_ParagraphStandardImageCarrousel {
                      fieldCarrouselImageItem {
                        entity {
                          ... on Drupal_ParagraphCarrouselImageItem {
                            fieldImageItem {
                              url
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldXpOtherExperiences {
            entity {
              ... on Drupal_ParagraphExperienceItem {
                fieldExperienceItem {
                  entity {
                    ... on Drupal_NodeExperience {
                      status
                      path {
                        alias
                      }
                      fieldExperienceName
                      fieldExperienceImageDesktop {
                        url
                        alt
                      }
                      fieldExperienceCategory {
                        entity {
                          ... on Drupal_TaxonomyTermXpCategories {
                            name
                          }
                        }
                      }
                      fieldExperienceBookingDetails {
                        entity {
                          ... on Drupal_ParagraphExperienceBookingDetails {
                            fieldBookingDetailsPrice
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `

export default Experience
